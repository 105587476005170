/**
 * @generated SignedSource<<d12db7403cfa6ee33900d79466a2b974>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type hooksHeaderMenuDisplayQuery$variables = Record<PropertyKey, never>;
export type hooksHeaderMenuDisplayQuery$data = {
  readonly queue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name?: string;
          readonly urlAlias?: string;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type hooksHeaderMenuDisplayQuery = {
  response: hooksHeaderMenuDisplayQuery$data;
  variables: hooksHeaderMenuDisplayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "name": "posties_menu_read"
    }
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 14
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Section",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "hooksHeaderMenuDisplayQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Queue",
        "kind": "LinkedField",
        "name": "queue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "QueueItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QueueItemsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "items(first:14)"
          }
        ],
        "storageKey": "queue(filter:{\"name\":\"posties_menu_read\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "hooksHeaderMenuDisplayQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Queue",
        "kind": "LinkedField",
        "name": "queue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "QueueItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QueueItemsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "items(first:14)"
          },
          (v3/*: any*/)
        ],
        "storageKey": "queue(filter:{\"name\":\"posties_menu_read\"})"
      }
    ]
  },
  "params": {
    "cacheID": "ac3c971e91fb8dadab07929c209c74a1",
    "id": null,
    "metadata": {},
    "name": "hooksHeaderMenuDisplayQuery",
    "operationKind": "query",
    "text": "query hooksHeaderMenuDisplayQuery {\n  queue(filter: {name: \"posties_menu_read\"}) {\n    items(first: 14) {\n      edges {\n        node {\n          __typename\n          ... on Section {\n            urlAlias\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "26ef77151ceebe90799526d2cf453bc7";

export default node;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontFredoka, theme, transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import {
  StyledLinkButton as ParentChildButton,
  StyledOffsetContainer as ParentChildButtonContainer,
} from "scmp-app/components/posties/button/styles";

import IconClose from "./icon-close.svg";
import IconHome from "./icon-home.svg";
import IconMenu from "./icon-menu.svg";
import IconStar from "./icon-star.svg";
import Logo from "./white-logo.svg";

const iconSize = "--hamburger-menu-icon-size";
const offsetTop = "--hamburger-menu-offset-top";
const offsetRight = "--hamburger-menu-offset-right";

const commonVariables = css`
  /* stylelint-disable csstree/validator */
  ${iconSize}: 30px;
  ${offsetTop}: 17px;
  ${offsetRight}: 16px;

  ${theme.breakpoints.up("tablet")} {
    ${iconSize}: 44px;
    ${offsetTop}: 27px;
    ${offsetRight}: 32px;
  }
`;

const commonSizes = css`
  inline-size: var(${iconSize});
  block-size: var(${iconSize});
`;

export const Container = styled.div`
  position: relative;

  ${commonVariables}
  ${commonSizes}
`;

export const StyledIconMenu = styled(IconMenu)`
  position: absolute;

  ${commonSizes}
`;

export const StyledIconClose = styled(IconClose)`
  position: absolute;

  ${commonSizes}
`;

export const StyledLogo = styled(Logo)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;

  inline-size: 150px;
  block-size: 24px;
  margin-block-start: 21px;
  margin-inline-start: 16px;
`;

export const Wrapper = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  inline-size: 200vw;
  block-size: 200vh;
  margin-block-start: calc(var(${offsetTop}) * -1);
  margin-inline-end: calc(var(${offsetRight}) * -1);

  text-align: end;

  cursor: default;

  overflow: hidden;
`;

export const Background = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  margin-block-start: calc(var(${offsetTop}) + calc(var(${iconSize}) / 2));
  margin-inline-end: calc(var(${offsetRight}) + calc(var(${iconSize}) / 2));

  border-radius: 50%;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  inline-size: 100vw;
  block-size: 100vh;
  padding-block: 160px;

  overflow-y: auto;

  ${ParentChildButtonContainer} {
    margin-block-start: 8px;

    &,
    & * {
      block-size: 43px;
    }

    ${ParentChildButton} {
      display: table-cell;

      font-size: 17.5px;
      line-height: 22.5px;
      vertical-align: middle;
    }
  }
`;

export const StyledIconStar = styled(IconStar)`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;

  display: none;

  inline-size: 28px;
  block-size: 34px;
  margin-block-start: -17px;
`;

export const StyledIconHome = styled(IconHome)`
  inline-size: 22.5px;
  block-size: 25px;
  margin-inline-end: 8px;
`;

const activeEffectStyles = css`
  padding-block: 8px;
  padding-inline: 32px;

  border-radius: 50px;

  background-color: #32549c;

  ${StyledIconStar} {
    display: block;

    margin-inline-start: -40px;
  }
`;

type BaseItemProps = {
  $isActive: boolean;
};

const BaseItem = styled(BaseLink, { ...transientOptions })<BaseItemProps>`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  color: #ffffff;
  font-family: ${fontFredoka};
`;

export const SubItem = styled(BaseItem)`
  margin-block-end: 24px;

  font-weight: 400;
  font-size: 20px;

  ${props =>
    props.$isActive
      ? css`
          ${activeEffectStyles}

          margin-block-start: -8px;
          margin-block-end: 16px;
        `
      : null}
`;

export const Item = styled(BaseItem)`
  margin-block-end: 40px;

  color: #ffffff;
  font-weight: 600;
  font-size: 28px;
  font-family: ${fontFredoka};

  ${SubItem}+& {
    margin-block-start: 32px;
  }

  ${props =>
    props.$isActive
      ? css`
          ${activeEffectStyles}

          margin-block-start: -8px;
          margin-block-end: 32px;

          ${SubItem}+& {
            margin-block-start: 24px;
          }
        `
      : null}
`;

export const Spacer = styled.div`
  flex: 1 1 auto;
`;

export const FallbackMenu = styled.div`
  inline-size: 30px;
  block-size: 30px;
`;

import { NoSsr } from "@mui/base";
import { animated } from "@react-spring/web";
import { useAtom } from "jotai";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback } from "react";

import { BaseLink } from "shared/components/common/base-link";
import { ClientSideSuspense } from "shared/components/common/client-side-suspense";
import { useEditionValue } from "shared/lib/edition";

import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { yieldToMain } from "scmp-app/lib/yield";
import { page as postiesPage } from "scmp-app/pages/posties";

import { useHamburgerMenuAnimation } from "./animations";
import { hamburgerMenuAtom } from "./atoms";
import {
  Background,
  Container,
  ContentWrapper,
  FallbackMenu,
  Spacer,
  StyledIconClose,
  StyledIconMenu,
  StyledLogo,
  Wrapper,
} from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
};

export const HamburgerMenu: FunctionComponent<Props> = ({ children, className }) => {
  const [state, setState] = useAtom(hamburgerMenuAtom);
  const AnimatedWrapper = animated(Wrapper);
  const AnimatedBackground = animated(Background);
  const AnimatedContentWrapper = animated(ContentWrapper);
  const { backgroundStyles, contentWrapperStyles, wrapperStyles } = useHamburgerMenuAnimation();
  const edition = useEditionValue();

  const { pathname, query } = postiesPage.route({});

  const handleLogoClick = useCallback(() => {
    setState({ isOpen: false });
  }, [setState]);

  const handleMenuToggleIconClick = useCallback(
    async (isOpen: boolean) => {
      await yieldToMain({ priority: "user-blocking" });
      setState(current => ({ ...current, isOpen }));

      await yieldToMain({ priority: "background" });
      sendGA4Tracking({
        action: "click",
        category: "menu",
        customized_parameters: {
          action_type: isOpen ? "close" : "open",
          edition,
          menu_type: "hamburger",
        },
        subcategory: "section",
      });
    },
    [setState, edition],
  );

  return (
    <ClientSideSuspense fallback={<FallbackMenu />}>
      <NoSsr>
        <Container className={className}>
          <AnimatedWrapper style={wrapperStyles}>
            <AnimatedBackground style={backgroundStyles} />
            <AnimatedContentWrapper style={contentWrapperStyles}>
              <BaseLink onClick={handleLogoClick} pathname={pathname} query={query}>
                <StyledLogo />
              </BaseLink>
              <Spacer />
              {children}
              <Spacer />
            </AnimatedContentWrapper>
          </AnimatedWrapper>
          {state.isOpen ? (
            <StyledIconClose onClick={() => handleMenuToggleIconClick(false)} />
          ) : (
            <StyledIconMenu onClick={() => handleMenuToggleIconClick(true)} />
          )}
        </Container>
      </NoSsr>
    </ClientSideSuspense>
  );
};

HamburgerMenu.displayName = "HamburgerMenu";
